@import url("https://use.typekit.net/uqb2fze.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient-yellow {
  width: 100vh;
  height: 100vh;
  position: absolute;
  left: 20%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #ffe76a 0%,
    rgba(255, 231, 106, 0) 100%
  );
  animation: orbit 10s linear infinite;
}
.gradient-red {
  width: 100vh;
  height: 100vh;
  position: absolute;
  left: 20%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #ff6abb 0%,
    rgba(255, 106, 187, 0) 100%
  );
  animation: orbit 10s linear infinite reverse;
}

@keyframes orbit {
  from {
    transform: rotate(0deg) translateX(25%) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(25%) rotate(-360deg);
  }
}

.neon {
  color: #000;
  text-shadow: 0 0 5px #f00, 0 0 10px #f00, 0 0 20px #f00, 0 0 40px #f0f,
    0 0 80px #f0f, 0 0 90px #f0f, 0 0 100px #f0f, 0 0 150px #f0f;
}
